/**
 * Öffne das Popup für Login, Reauth und, Exception
 * mit abgedunkeltem / unscharfen Hintergrund
 */

// import Modernizr
import * as loader from "@shopJS/loader";
import * as utils from "@sharedJS/utils";
import * as reauthentication from "./loginReauthentication";
import * as loginException from "./loginException";
import pushDataLayer from "@ocm/services/dataLayer.js";
import OcmMessenger from "ocm_mercurius_messenger";
import { messengerPublicationTypes } from "@ocm/services/services.constants.js";
import { getRecommendation } from "@sharedJS/utils";

let $commonPopup;
let $popupBackground;
let $popupContent;

const $commonlayer = $("#master-common-layer");

// Layer Types
const Registration = "Registration"; // registrationForm.jsp
const LoginRegistration = "LoginRegistration"; // loginRegistrationForm.jsp
const Authentication = "Authentication"; // authenticationForm.jsp
const Exception = "Exception"; // errorLayerPage.jsp

const opacity = 0.7; //should be same as in tailwind.config color/dimmer
let $selectedLayer;
let lastLayerName = "";
let isOpen = false;
let isInitialized;
let _isLoginLayerFirstTime = true;
let _isRegisterLayerFirstTime = true;

const init = function () {
	if (isInitialized) {
		return;
	}
	isInitialized = true;

	$commonPopup = $("#js-common-popup");
	if ($commonPopup.length < 1) {
		return;
	}
	$popupContent = $commonPopup.find(".popupContent");

	$commonPopup.popup({
		autoopen: false,
		opacity,
		detach: false,
		blur: false,
		escape: false,
		onopen: popupOpenHandler,
		onclose: popupCloseHandler,
	});

	OcmMessenger.subscribe(messengerPublicationTypes.REQUEST_LOGIN, _listenToRequestLogin, {
		REACT_TO_LATEST: true,
	});

	addEventListener("popstate", () => isOpen && closeLayer());

	// check for initial Layer
	const _selectedLayerName = document.getElementById("master-common-layer")?.getAttribute("data-layerselected") || null;
	if (_selectedLayerName) appendLayer(_selectedLayerName);
};

const _MFAddLoginListener = function () {
	OcmMessenger.subscribe(messengerPublicationTypes.ABORTED_LOGIN, closeLayer); // todo: Login Microfrontend / Layer in MF
};

const _listenToRequestLogin = function (publication) {
	const selectedLayer = document.getElementById("master-common-layer")?.getAttribute("data-layerselected") || null;
	const isClosable = publication.data?.isClosable || false;
	showLayer(selectedLayer, isClosable);
};

// Aufgerufen von
// - hier (über REQUEST_LOGIN), z.B. commonPopupTrigger -> Welcome-Layer
// - loginReauthentication.js
// - loginException.js
const showLayer = function (layerName, isClosable = false) {
	if (isOpen || !layerName) return;

	appendLayer(layerName);
	if (!$selectedLayer) return;

	isOpen = true;
	initLayerContent(layerName);

	if ([Authentication].includes(layerName)) {
		isClosable = true;
	}

	if ([Registration, LoginRegistration].includes(layerName)) {
		$popupContent.closest(".c-popup--reset").addClass("hide-scrollbars");
		loginRegistrationLayerTracking();
	}

	setPopupOptions(isClosable);
	$selectedLayer.parent().parent().addClass("animated");

	if ($commonPopup.data("popupVisible") !== true) {
		$commonPopup.popup("show");
		$selectedLayer.removeClass("is-hidden slideOutDownNoScale").addClass("animated slideInUpNoScale");
	}

	if (layerName === "LoginRegistration") history.pushState({ isLoginRegistrationOpen: true }, "", "");
};

const loginRegistrationLayerTracking = function () {
	const trackingContext = $selectedLayer.find("#mf-login > :first-child").attr("data-track");
	if (!trackingContext) return;

	const pageName = getRecommendation()?.pageData?.pageName;
	// todo: es kann sein, dass getRecommendation mit SPA nicht mehr zuverlässig ist.
	// todo: Klären obe es eher pageData.pageUid ist
	let category;
	let action;

	if (trackingContext === "LoginLayer") {
		category = "LoginLayer";
		action = _isRegisterLayerFirstTime ? "LoginLayerShown1st" : "LoginLayerShownMulti";
		_isRegisterLayerFirstTime = false;
	} else if (trackingContext === "RegistrationLayer") {
		// todo:  bisher gab es hier keine Unterscheidung ???
		// todo: Frage:  wird nicht bei geöffneten LoginLayer mit wechsel zu RegistrationLayer ausgelöst
		category = "RegistrationLayer";
		action = _isLoginLayerFirstTime ? "RegistrationLayerShown1st" : "RegistrationLayerShownMulti";
		_isLoginLayerFirstTime = false;
	}
	pushDataLayer({
		event: "GAevent",
		event_category: `S_${category}`,
		event_action: `S_${action}`,
		event_label: `S_${pageName}`,
	});
};

const setPopupOptions = function (isClosable) {
	const $closeButton = $(".js-common-popup_close");
	const popupOptions = $commonPopup.data("popupoptions");
	if (isClosable) {
		const isMobile = Modernizr.touchevents || !Modernizr.mq("(min-width: 1024px)");
		popupOptions.blur = !isMobile;
		popupOptions.escape = !isMobile;
		popupOptions.closebutton = true;
		popupOptions.opacity = opacity;
		popupOptions.closeElement = "js-common-popup_close";

		$closeButton.show();
	} else {
		popupOptions.blur = false;
		popupOptions.escape = false;
		popupOptions.closebutton = false;
		popupOptions.opacity = opacity;

		$closeButton.hide();
	}
};

const appendLayer = function (selectedLayerName) {
	if (selectedLayerName && selectedLayerName !== lastLayerName) {
		$selectedLayer = $(`#${selectedLayerName}-layer`);
		if ($popupContent.children().length > 0) {
			$popupContent.children().removeClass("is-visible");
			$commonlayer.append($popupContent.children());
		}
		$popupContent.append($selectedLayer);
		lastLayerName = selectedLayerName;
	}
};

const closeLayer = function () {
	if (!isOpen) return;

	const animationEndEvents = "webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend";
	$popupBackground = $("#js-common-popup_background");
	$selectedLayer.removeClass("slideInUpNoScale").addClass("animated slideOutDownNoScale");
	$popupBackground.css("opacity", "0");
	$selectedLayer.on(animationEndEvents, () => {
		isOpen = false;
		$commonPopup.popup("hide");
		$selectedLayer.off(animationEndEvents);
	});
};

const setPopupPostActions = function (command, url) {
	if ($commonlayer.length > 0) {
		$commonlayer.attr("data-postActionJs", command);
		$commonlayer.attr("data-postActionUrl", url);
	}
};

// /!\ data wird für `eval(postAction)` benötigt
// eslint-disable-next-line no-unused-vars
const doStartPopupPostAction = function () {
	if ($commonlayer.length > 0) {
		const postAction = $commonlayer.attr("data-postActionJs");
		const postUrl = $commonlayer.attr("data-postActionUrl");
		if (postAction.length > 0) {
			doDisplayShoppingBag();
			eval(postAction);
		}
		if (postUrl.length > 0) {
			window.location.replace(utils.getUrl(postUrl));
		}
	}
};

const doDisplayShoppingBag = function () {
	if (!reauthentication.doReauth(500)) {
		const miniCartCounter = $(".js-miniCartCounter-badge");
		if (miniCartCounter.length > 0 && miniCartCounter.hasClass("is-hidden")) {
			miniCartCounter.removeClass("is-hidden");
		}
	}
};

const initLayerContent = function (layerName) {
	switch (layerName) {
		case Authentication:
			reauthentication.init();
			reauthentication.openActions();
			break;
		case Exception:
			loginException.init();
			break;
	}
};

const popupOpenHandler = function () {
	_MFAddLoginListener();
};

const popupCloseHandler = function () {
	loader.removeLoader();
	isOpen = false;
	if (history.state?.isLoginRegistrationOpen) history.back();
};

init();

const commonPopup = {
	init,
	showLayer,
	closeLayer,
	doStartPopupPostAction,
	setPopupPostActions,
};

export default commonPopup;
