import { sendTracking } from "./infoModalsDataLayer";

const modalSelector = ".info-modal";
const triggerClass = "js-info-modal-trigger";
const closeButtonSelector = ".js-close-info-modal";
const openClass = "is-visible";
let mainEl = null;
let triggerEl = null;

/**
 * @param {HTMLElement} modal
 * @param {HTMLElement} _triggerEl
 */
const showModal = async (modal, _triggerEl) => {
	modal.classList.add(openClass);
	if (mainEl) mainEl.inert = true;
	await new Promise((resolve) => window.setTimeout(resolve, 60));
	modal.querySelector(closeButtonSelector)?.focus();

	if (_triggerEl) triggerEl = _triggerEl;
};

/**
 * @param {HTMLElement} modal
 */
const hideModal = (modal) => {
	if (mainEl) mainEl.inert = false;
	modal.classList.remove(openClass);
	modal.dispatchEvent(new Event("hideModal"));

	if (triggerEl) triggerEl.focus();
	triggerEl = null;
};

const init = () => {
	mainEl = document.querySelector("#js-main-container");

	const modals = document.querySelectorAll(modalSelector);
	[...modals].forEach((modal) => {
		const triggers = [modal.previousElementSibling].filter((el) => el.classList.contains(triggerClass));
		const closeButtons = [...modal.querySelectorAll(closeButtonSelector)];
		const trackCategory = modal.dataset.trackCategory;

		document.body.appendChild(modal);

		const _showModal = (event) => {
			showModal(modal, event.currentTarget);
			// tracking
			const trackAction = event.currentTarget?.dataset.trackAction || `${trackCategory}Shown`;
			sendTracking(trackCategory, trackAction);
		};
		const _hideModal = (event) => {
			hideModal(modal);
			// tracking
			const trackAction = event.currentTarget?.dataset.trackAction || `${trackCategory}Closed`;
			sendTracking(trackCategory, trackAction);
		};

		// initial tracking
		if (modal.classList.contains(openClass)) sendTracking(trackCategory, `${trackCategory}Shown`);

		triggers.forEach((el) => el.addEventListener("click", _showModal));
		closeButtons.forEach((el) => el.addEventListener("click", _hideModal));
		modal.addEventListener("click", (e) => e.target === modal && _hideModal(e));
	});
};

export { init, showModal, hideModal };
